import { useCallback, useEffect, useState } from 'react';
import { useFetcher, useNavigation } from 'react-router-dom';

import { queryClient } from '@f4s/api-client';
import { Button, Card, cn, Icon, Input } from '@f4s/ui';

import { ProfileAvatar } from '@/components/avatar';
import { NavLinkButton } from '@/components/nav-link';
import { PageHeader } from '@/components/page-header';
import { useDataFromLoader } from '@/lib/hooks/loader';
import { useDebounce } from '@/lib/hooks/use-debounce';
import {
  findSuggestedWorkspacesQuery,
  useFindSuggestedWorkspaces,
} from '@/modules/workspace/queries';

import type { onboardingSpacesJoinLoader } from '../loaders';

type SearchResult = NonNullable<
  ReturnType<typeof useFindSuggestedWorkspaces>['data']
>[number];

const SpaceJoinButton = ({
  space,
  onClick,
  isLoading,
}: {
  space: SearchResult;
  onClick: (space: SearchResult) => void;
  isLoading?: boolean;
}) => {
  return (
    <Button
      onClick={() => onClick(space)}
      variant={
        !space.isMember && space.permissions.autoApproveJoinRequest
          ? 'default'
          : 'secondary'
      }
      disabled={space.isMember || space.isPending || isLoading}
      data-testid="onboarding-spaces-join-join-button"
    >
      {space.isMember
        ? 'Joined'
        : space.isPending
          ? 'Request pending'
          : space.permissions.autoApproveJoinRequest
            ? 'Join'
            : 'Request to join'}
    </Button>
  );
};

export const OnboardingSpaceJoinPage = () => {
  const { suggested, workDomains } =
    useDataFromLoader<typeof onboardingSpacesJoinLoader>();
  const fetcher = useFetcher();
  const navigation = useNavigation();

  const [search, setSearch] = useState<string>('');
  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);
  const [debounced, isDebouncing] = useDebounce(search, 300);
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');

  useEffect(() => {
    setDebouncedSearch((prev) => {
      // Cancel the previous query
      if (debounced && debounced !== prev) {
        queryClient
          .cancelQueries(findSuggestedWorkspacesQuery({ search: prev }))
          .catch((error) => console.warn('Error cancelling query', error));
      }

      return debounced;
    });
  }, [debounced]);

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const { data: foundSpaces, isFetching } = useFindSuggestedWorkspaces({
    search: debouncedSearch,
    enabled: fetcher.state === 'idle' && navigation.state === 'idle',
  });
  useEffect(() => {
    if (foundSpaces) {
      setSearchResults(foundSpaces);
    }
  }, [foundSpaces]);

  const handleJoin = useCallback(
    (workspace: { id: number; isMember?: boolean }) => {
      if (workspace.isMember) return;
      fetcher.submit(
        { workspaceId: workspace.id },
        { method: 'POST', encType: 'application/json' },
      );
    },
    [fetcher],
  );

  return (
    <>
      <div className="flex w-full flex-col gap-4">
        <PageHeader
          title="Let's get you into a space"
          description="Spaces bring your team together in one shared place - so you can connect, collaborate, and uncover powerful insights as a team, with friends, or even family - not just as individuals. This is where the real magic happens!"
        />
        <div className="relative flex items-center">
          <Input
            placeholder="Find a space to join..."
            value={search}
            onChange={handleSearch}
            type="text"
          />
          <Icon.SpinnerSmall
            className={cn(
              'absolute right-3 hidden h-7 w-7 animate-spin',
              search && (isDebouncing || isFetching) && 'block',
            )}
          />
        </div>

        {searchResults.length > 0 && (
          <div className="flex w-full flex-col gap-4">
            {searchResults.map((r) => (
              <Card key={r.id} className="flex items-center gap-4 p-4">
                <ProfileAvatar
                  avatarUrl={r.avatarUrl}
                  initials={r.name
                    .split(' ')
                    .map((n) => n[0])
                    .join('')}
                  size="xl"
                  className="rounded-lg"
                />
                <div className="grid flex-1 items-center gap-x-2 gap-y-1 [grid-template-columns:auto_1fr]">
                  <div className="col-span-2 font-serif text-lg font-semibold">
                    {r.name}
                  </div>
                  {r.companyName && (
                    <div className="col-span-2 flex items-center gap-2 truncate whitespace-nowrap">
                      <span className="truncate">@{r.companyName}</span>
                    </div>
                  )}
                </div>
                <SpaceJoinButton
                  space={r}
                  onClick={handleJoin}
                  isLoading={fetcher.state !== 'idle' || navigation.state !== 'idle'}
                />
              </Card>
            ))}
          </div>
        )}
      </div>
      <div className="flex w-full flex-col gap-2">
        {suggested.length > 0 && (
          <div className="flex w-full flex-col gap-2">
            <p className="text-pretty">
              We&apos;ve found{' '}
              {suggested.length === 1 ? 'an existing space' : 'some existing spaces'}{' '}
              linked to your{' '}
              {workDomains.length === 1 ? (
                <>
                  <span className="font-semibold">{workDomains[0]}</span> email
                </>
              ) : (
                'work domains'
              )}
            </p>
            {suggested.map((w) => (
              <Card key={w.id} className="flex items-center gap-4 p-4">
                <ProfileAvatar
                  avatarUrl={w.avatarUrl}
                  initials={w.name
                    .split(' ')
                    .map((n) => n[0])
                    .join('')}
                  size="xl"
                  className="rounded-lg"
                />
                <div className="grid flex-1 items-center gap-x-2 gap-y-1 [grid-template-columns:auto_1fr]">
                  <div className="col-span-2 font-serif text-lg font-semibold">
                    {w.name}
                  </div>
                  <div>{w.memberCount} members</div>
                </div>
                <SpaceJoinButton
                  space={w}
                  onClick={handleJoin}
                  isLoading={fetcher.state !== 'idle' || navigation.state !== 'idle'}
                />
              </Card>
            ))}
          </div>
        )}
        <Card className="bg-muted flex flex-col items-start justify-center gap-6 p-6">
          <div className="flex flex-col gap-2">
            <h2 className="font-serif text-lg font-semibold">Not seeing your space?</h2>
            <p className="text-muted-foreground text-sm">
              No worries &mdash; create one now and invite your team, friends, or family
              when you’re ready.
            </p>
          </div>
          <NavLinkButton
            to="/onboarding/spaces/create"
            variant={suggested.length > 0 ? 'secondary' : 'default'}
            disabled={fetcher.state !== 'idle' || navigation.state !== 'idle'}
            data-testid="onboarding-spaces-join-create-button"
          >
            Create a new space
          </NavLinkButton>
        </Card>
      </div>

      {suggested.some((w) => w.isMember) && (
        <div className="flex w-full justify-end">
          <NavLinkButton
            variant="secondary"
            to="/onboarding/spaces/complete"
            disabled={fetcher.state !== 'idle' || navigation.state !== 'idle'}
            data-testid="onboarding-spaces-join-continue-button"
          >
            Continue
          </NavLinkButton>
        </div>
      )}
    </>
  );
};
