import { NavLink } from '@/components/nav-link';

import { LoginForm } from '../components/login';

export const LoginPage = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-2">
        <h1 className="font-serif text-2xl font-semibold tracking-tight">
          Log in to Marlee
        </h1>
        <p>
          Don&apos;t have an account?{' '}
          <NavLink to="/signup" childFocus={false} className="text-primary">
            Signup
          </NavLink>
        </p>
      </div>
      <LoginForm redirectTo="/onboarding" forLogin />
    </>
  );
};
